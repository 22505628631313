.calc-page {
    width: 100vw;
    max-width: 100%;
    height: 100vh;
}

.exp {
    height: 1050px !important;
    max-height: 100%;
}

.calc-background {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    max-width: 100%;
    height: 100%;
}

.calc-main {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 100px;
    width: 800px;
    height: 450px;
    backdrop-filter: blur(20px) saturate(200%);
    -webkit-backdrop-filter: blur(20px) saturate(200%);
    background-color: rgba(255, 255, 255, 0.91);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border: 1px solid rgba(209, 213, 219, 0.3);
}

.calc-zagolovok {
    font-family: "Roboto"; font-size: 30px; font-weight: 400; color:#444;
}

.calc-from-to {
    display: flex;
    flex-direction: row;
}

.calc-from-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 60px;
    width: 350px;
}

.calc-titles{
    font-family: "Roboto"; font-size: 15px; font-weight: 500; color:#AEAEAE;
}

.calc-from-line {
    margin-top: 10px;
    width: 350px;
    height: 50px;
    border: 2px solid rgb(181, 181, 181);
    background-color: rgba(239, 239, 239, 0.766);
}

.calc-to-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 350px;
}

.calc-to-line {
    margin-top: 10px;
    width: 350px;
    height: 50px;
    border: 2px solid rgb(181, 181, 181);
    background-color: rgba(239, 239, 239, 0.766);
}

.calc-type-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    width: 760px;
}

.calc-type-line {
    margin-top: 10px;
    width: 760px;
    text-align: center;
    height: 50px;
    border: 2px solid rgb(181, 181, 181);
    background-color: rgba(239, 239, 239, 0.766);
}

.p-icon {
    display: none;
}

.p-dropdown {
    display: flex;
    align-items: center;
    text-align: center;
    height: 100%;
    width: 100%;
    font-family: "Roboto"; font-size: 18px; color:#444;
}

.p-placeholder {
    font-family: "Roboto"; font-size: 18px; color:#818181;
}

.p-dropdown-filter-container {
    margin-top: 1px;
    border: 1px solid rgb(181, 181, 181);
    border-left: 2px solid rgb(181, 181, 181);
    border-right: 2px solid rgb(181, 181, 181);
    background-color: #FFFFFF;
    border-bottom: none;
    padding: 3px;
    width: 100.5%;
    margin-left: -2px;
}

.p-dropdown-filter {
    width: 100%;
    border-radius: 3px;
    height: 30px;
    font-family: "Roboto"; font-size: 17px; font-weight: 400; color:#444;
}

.p-dropdown-items-wrapper {
    width: 100.5%;
    border: 2px solid rgb(181, 181, 181);
    border-top: none;
    background-color: #FFFFFF;
    margin-left: -2px;
}

.p-dropdown-items-wrapper::-webkit-scrollbar {
    width: 7px;
}

.p-dropdown-items-wrapper::-webkit-scrollbar-thumb {
    height: 30px;
    border-radius: 20px;
    background: #c9c9c9;
}

.p-dropdown-item {
    height: fit-content;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 3px;
    font-family: "Roboto"; font-size: 16px; font-weight: 400; color:#444;
    text-wrap: wrap;
    width: 344px;
}

.calc-type-item {
    width: 100%;
}

.p-dropdown-item:hover {
    background-color: #5897fb;
    color: white;
}

.p-dropdown-empty-message {
    font-family: "Roboto"; font-size: 0.5px; font-weight: 400; color:#444;
    visibility: hidden;
}

.p-dropdown-empty-message::after {
    visibility: visible;
    font-family: "Roboto"; font-size: 16px; font-weight: 400; color:#444;
    content: "Ничего не найдено";
}

.calc-data {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
}

.calc-data-blocks {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 170px;
}

.calc-weight-block {
    margin-right: 27px;
}

.calc-volume-block {
    margin-right: 27px;
    margin-top: -3px;
}

.calc-amount-block {
    margin-right: 27px;
}

.calc-data-lines {
    margin-top: 10px;
}

.calc-data-button {
    margin-top: 29px;
    width: 170px;
    height: 50px;
    border: none;
    background-color: #58bc51;
    font-family: "Roboto"; font-size: 18px; font-weight: 500;color: white;
}

.calc-data .p-inputtext {
    width: 170px;
    height: 50px;
    padding-left: 5px;
    background-color: rgba(239, 239, 239, 0.766);
    border: 2px solid rgb(181, 181, 181);
    font-family: "Roboto"; font-size: 16px; font-weight: 400; color:#444;
}

.wrong-input{
    border: 2px solid rgba(255, 0, 0, 0.514) !important;
}

.answer {
    display: flex;
    flex-direction: column;
    width: 800px;
    backdrop-filter: blur(20px) saturate(200%);
    -webkit-backdrop-filter: blur(20px) saturate(200%);
    background-color: rgba(255, 255, 255, 0.91);
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border: 1px solid rgba(209, 213, 219, 0.3);
    border-top: 2px solid rgba(151, 151, 151, 0.3);
    height: 300px;
}

.answer-from-to {
    margin-top: 30px;
    margin-left: 25px;
    font-family: "Roboto"; font-size: 16px; font-weight: 500; color:#4586F1
}

.answer-table {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

.answer-table table {
    border: 1px solid #bababa;
    height: 100%;
    width: 750px;
    border-collapse: collapse;
    text-align: center;
    font-family: "Roboto"; font-size: 14px; font-weight: 400; color:#575757;
}

.answer-table th {
    height: 35px;
    border: 1px solid #bababa;
    background-color: #77777718;
}

@media screen and (min-width: 1200px) {
    .answer-table th:nth-child(1) {
        width: 180px;
    }
    
    .answer-table th:nth-child(5) {
        width: 80px;
    }

    .popup {
        position: fixed;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100%;
        overflow: auto;
        background-color: rgba(0,0,0,0.4);
    }
    
    .popup-inner {
        background-color: white;
        position: fixed;
        z-index: 2;
        left: 34%;
        top: 25%;
        border-radius: 5px;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
        width: 500px;
        height: 400px;
    }
    
    .popup-inner form {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
       
    .popup-title {
        font-family: "Roboto"; font-size: 32px; font-weight: 600;color: rgb(0, 0, 0);
        margin-top: 50px;
    }
    
    .popup-subtitle {
        font-family: "Roboto"; font-size: 14px; font-weight: 300;color: rgb(0, 0, 0);
        margin-top: 10px;
    }
    
    .popup-input-name {
        width: 370px;
        height: 50px;
        border: 1px solid #c9c9c9;
        border-radius: 5px;
        margin-top: 30px;
        padding-left: 20px;
        font-family: "Roboto"; font-size: 16px; font-weight: 400;color: #000000;
    }
    
    .popup-input-phone {
        width: 370px;
        height: 55px;
        border: 1px solid #c9c9c9;
        border-radius: 5px;
        margin-top: 30px;
        padding-left: 20px;
    }
    
    .popup-button {
        margin-top: 30px;
        width: 370px;
        height: 50px;
        border-radius: 5px;
        border: none;
        background-color: #58bc51;
        font-family: "Roboto"; font-size: 18px; font-weight: 500;color: white;
    }
    
    .popup-input-name::placeholder {
        font-family: "Roboto"; font-size: 16px; font-weight: 300;color: #b6b6b6;
    }
    
}

.answer-table td {
    height: 40px;
    border: 1px solid #bababa;
}

.choosed {
    background-color: #c0f9bc;
}

.asnwer-result {
    font-family: "Roboto"; font-size: 25px; font-weight: 400; color:#58bc51;
    margin-top: 25px;
    margin-left: 500px;
}

.answer-button {
    margin-top: 40px;
    margin-left: 210px;
    width: 400px;
    height: 50px;
    border: none;
    background-color: #58bc51;
    font-family: "Roboto"; font-size: 18px; font-weight: 500;color: white;
}

.wrongInput {
    border-color: red !important;
}

@media screen and (max-width:1200px) {
    .calc-page {
        width: 100%;
        height: 100vh;
    }

    .exp {
        height: 1800px !important;
        max-height: 100%;
    }

    .calc-main {
        width: 98%;
        height: fit-content;
        margin-top: 50px;
        border-bottom: 2px solid rgba(151, 151, 151, 0.3);
    }

    .calc-zagolovok {
        font-family: "Roboto"; font-size: 20px; font-weight: 400; color:#444;
        text-align: center;
    }

    .calc-from-to {
        flex-direction: column;
        width: 100%;
    }

    .calc-from-block {
        width: 100%;
        margin-right: 0;
    }

    .calc-from-line {
        width: 92%;
    }

    .calc-to-block {
        width: 100%;
        margin-top: 10px;
    }

    .calc-to-line {
        width: 92%;
    }

    .calc-type-block {
        width: 100%;
        margin-top: 10px;
    }

    .calc-type-line {
        width: 92%;
    }

    .calc-data {
        display: flexbox;
        width: 100%;
        flex-wrap: wrap;
        margin-top: 10px;
    }

    .calc-data-blocks{
        width: 130px;
        margin-left: 20px;
        margin-top: 5px;
        margin-bottom: 20px;
    }

    @media screen and (min-width:360px) and (max-width:545px) {
        .calc-amount-block {
            margin-left: 0;
        }
    }

    .calc-data-lines {
        margin-top: 5px;
    }

    .calc-data .p-inputtext {
        width: 140px;
    }

    .calc-data-button {
        width: 140px;
        margin-left: 15px;
        margin-bottom: 20px;
    }

    .p-dropdown-filter-container {
        margin-top: 1px;
        border: 1px solid rgb(181, 181, 181);
        border-left: 2px solid rgb(181, 181, 181);
        border-right: 2px solid rgb(181, 181, 181);
        background-color: #FFFFFF;
        border-bottom: none;
        padding: 3px;
        width: 100%;
        margin-left: 0;
    }

    .p-dropdown-items-wrapper {
        width: 100%;
        border: 2px solid rgb(181, 181, 181);
        border-top: none;
        background-color: #FFFFFF;
        margin-left: 0;
    }    

    .p-dropdown-item {
        width: 100%;
    }
    
    .answer {
        display: flex;
        flex-direction: column;
        width: 98%;
        backdrop-filter: blur(20px) saturate(200%);
        -webkit-backdrop-filter: blur(20px) saturate(200%);
        background-color: rgba(255, 255, 255, 0.91);
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        border: 1px solid rgba(209, 213, 219, 0.3);
        border-top: 2px solid rgba(151, 151, 151, 0.3);
        height: 400px;
    }
    
    .answer-from-to {
        margin-top: 30px;
        margin-left: 25px;
        font-family: "Roboto"; font-size: 16px; font-weight: 500; color:#4586F1
    }
    
    .answer-table {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .answer-table table {
        border: 1px solid #bababa;
        margin-top: 20px;
        height: 100%;
        width: 90%;
        border-collapse: collapse;
        text-align: center;
        font-family: "Roboto"; font-size: 14px; font-weight: 400; color:#575757;
    }
    
    .answer-table tr {
        display: flex;
        flex-direction: column;
    }
    
    .answer-table th {
        padding-bottom: 5px;
        height: 35px;
        border: none;
        background-color: #77777718;
    }
    
    .answer-table input[type="radio"] {
        position: relative;
        top: 2px;
    }
    
    .answer-table td {
        height: 20px;
        padding-top: 10px;
        border: none;
    }

    .answer-table-price {
        font-family: "Roboto"; font-size: 16px; font-weight: 600; color:#44903f;
        margin-top: 5px;
        padding-bottom: 10px;
    }

    .truck {
        width: 20px;
        position: relative;
        top: 5px;
        margin-left: 5px;
        margin-right: 5px;
    }

    .down-arrow {
        width: 15px;
        position: relative;
        top: 5px;
    }
    
    .choosed {
        background-color: #c0f9bc;
    }
    
    .asnwer-result {
        font-family: "Roboto"; font-size: 23px; font-weight: 400; color:#58bc51;
        margin-top: 30px;
        align-self: center;
        margin-left: 0;
    }
    
    .answer-button {
        align-self: center;
        margin-left: 0;
        margin-top: 10px;
        width: 90%;
        max-width: 320px;
        height: 50px;
        border: none;
        background-color: #58bc51;
        font-family: "Roboto"; font-size: 16px; font-weight: 500;color: white;
    }
    
    .popup {
        position: fixed;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100%;
        overflow: auto;
        background-color: rgba(0,0,0,0.4);
    }
    
    .popup-block {
        display: flex;
        justify-content: center;
    }

    .popup-inner {
        background-color: white;
        position: fixed;
        z-index: 2;
        top: 30%;
        border-radius: 5px;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
        width: 400px;
        height: 400px;
    }
    
    .popup-inner form {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
       
    .popup-title {
        font-family: "Roboto"; font-size: 32px; font-weight: 600;color: rgb(0, 0, 0);
        margin-top: 50px;
    }
    
    .popup-subtitle {
        font-family: "Roboto"; font-size: 14px; font-weight: 400;color: rgb(0, 0, 0);
        margin-top: 10px;
    }
    
    .popup-input-name {
        width: 300px;
        height: 50px;
        border: 1px solid #c9c9c9;
        border-radius: 5px;
        margin-top: 30px;
        padding-left: 20px;
        font-family: "Roboto"; font-size: 16px; font-weight: 400;color: #000000;
    }
    
    .popup-input-phone {
        width: 300px;
        height: 55px;
        border: 1px solid #c9c9c9;
        border-radius: 5px;
        margin-top: 30px;
        padding-left: 20px;
    }
    
    .popup-button {
        margin-top: 30px;
        width: 370px;
        height: 50px;
        border-radius: 5px;
        border: none;
        background-color: #58bc51;
        font-family: "Roboto"; font-size: 18px; font-weight: 500;color: white;
    }
    
    .popup-input-name::placeholder {
        font-family: "Roboto"; font-size: 16px; font-weight: 300;color: #b6b6b6;
    }

}