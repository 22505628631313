.header-wrapper {
    width: 100vw;
    max-width: 100%;
    height: 55px;
    background-color: rgb(145, 139, 139, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
}

.back-to-main {
    font-family: "Roboto"; font-size: 14px; font-weight: 600; color:#ffffff;
    margin-right: 650px;
}

a {
    top: 10px;
    position: absolute;
    left: 40px;
}

.back-to-main a {
    position: unset;
    color: inherit;
    text-decoration: inherit;
}

@media screen and (max-width: 1200px) {
    .header-wrapper {
        width: 100%;
        height: 100px;
        flex-direction: column;
    }

    .back-to-main {
        margin-right: 0;
        margin-top: 15px;
    }

    a {
        position: unset;
    }
    
    .logo-img {
        position: unset;
    }
}